var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$style.root},[_c('div',{staticClass:"mb-4"},[(_vm.$route.meta.title)?_c('h1',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$route.meta.title))]):_vm._e()]),_c('div',{staticClass:"mb-4 clearfix"},[_c('div',{staticClass:"float-left"},[_c('VBtn',{attrs:{"depressed":""},on:{"click":function($event){return _vm.$router.push({ name: 'user/ProfileView' })}}},[_c('VIcon',[_vm._v("mdi-arrow-left")]),_vm._v(" Назад ")],1)],1),_c('div',{staticClass:"float-right"},[(!!_vm.authLink.length)?_c('VBtn',{attrs:{"href":_vm.authLink,"color":"success","depressed":""}},[_vm._v(" Перейти в ЛК Поставщика "),_c('VIcon',[_vm._v("mdi-arrow-right")])],1):_vm._e()],1)]),_c('div',{class:_vm.$style.inner},[_c('ApplicationList',{attrs:{"headers":[
          { text: 'ID', value: 'id', sortable: false, filter: true },
          { text: 'ИНН', value: 'inn', sortable: false, filter: true },
          { text: 'ОГРН', value: 'ogrn', sortable: false, filter: true },
          { text: 'Организация', value: 'name', alias: 'companyName', sortable: false, filter: true },
          { text: 'Статус', value: 'status.name', alias: 'status', options: _vm.statuses, sortable: false, filter: true },
          { text: 'Код MDG поставщика', value: 'codeMdg', sortable: false, filter: true },
          { text: 'Номер договора', value: 'contractNumber', sortable: false, filter: true },
          { text: 'Действия', value: 'actions', sortable: false, filter: false },
        ],"items":_vm.applications.items,"count":_vm.applications.count,"page":_vm.page,"size":_vm.size,"loading":_vm.loading,"submit-decision-handler":_vm.onSubmitDecision}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }