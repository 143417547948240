<template>
  <div :class="$style.root">
      <VMenu top offset-y open-on-hover>
      <template v-slot:activator="{ on, attrs }">
        <div :class="$style.inner">
          <span v-bind="attrs" v-on="on">{{ text }}</span>
        </div>
      </template>
      <VList>
        <VListItem :class="$style.item">
          <VListItemTitle :class="$style.title">{{ text }}</VListItemTitle>
        </VListItem>
      </VList>
    </VMenu>
  </div>
</template>

<script>
export default {
  name: 'CompactList',
  props: {
    text: { type: String },
  },
}
</script>

<style module lang="scss">
.root {
  display: flex;
  width: 100%;
  align-items: flex-end;
}
.inner {
  word-break: break-all;
  overflow: hidden;
  cursor: pointer;
  max-height: 3em;
  display: -webkit-box;
  max-width: 400px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.item {
  min-height: 30px;
}
.title {
  font-size: 14px;
}
</style>
