<template>
  <div :class="$style.root">
    <VDataTable
        fixed-header
        height="calc(100vh - 200px)"
        hideDefaultHeader
        :headers="headers"
        :items="listItems"
        :loading="loading"
        :footer-props="footer"
        :options.sync="options"
        :server-items-length="count"
        :mobile-breakpoint="0"
        @click:row="({ id }) => onDetail(id)"
        @update:page="page => onPagination({ page })"
        @update:items-per-page="size => onPagination({ size })"
    >
      <template v-slot:header>
        <thead class="v-data-table-header">
          <tr>
            <th v-for="{text, colspan } in headers" class="text-start" :colspan="colspan" :key="text">{{text}}</th>
          </tr>
          <FilterRow :items="headers" @input="onPagination" />
        </thead>
      </template>
      <template v-slot:item.name="{ item: { name } }">
        <CompactCeil :text="name" />
      </template>
      <template v-slot:item.contractNumber="{ item: { contractNumber } }">
        <CompactCeil :text="contractNumber" />
      </template>
      <template v-slot:item.actions="{ item: { status, id } }">
          <ApprovingButtons :state="status.value" :pending="pending" :id="id" @submit-decision="submitDecisionHandler" small />
      </template>
    </VDataTable>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {map} from 'lodash-es';
import { ROLES } from '@/store/spa/enums';
import FilterRow from '@/components/spa/FilterRow/FilterRow';
import CompactCeil from '@/components/spa/ApplicationList/components/CompactCeil';
import ApprovingButtons from '@/components/spa/ApprovingButtons/ApprovingButtons.vue';

export default {
  name: 'ApplicationList',
  components: {
    CompactCeil,
    FilterRow,
    ApprovingButtons,
  },
  props: {
    headers: { type: Array },
    items: { type: Array },
    count: { type: Number },
    page: { type: Number },
    size: { type: Number },
    loading: { type: Boolean },
    submitDecisionHandler: { type: Function, required: true },
  },
  data: function() {
    return {
      footer: {
        itemsPerPageText: '',
        showFirstLastPage: true,
        itemsPerPageOptions: [5, 10, 20, 50, 100, 500],
      },
      options: {
        page: this.page,
        itemsPerPage: this.size,
      },
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/current',
      hasRole: 'user/hasRole',
      pending: 'spa/pending',
    }),
    listItems() {
      if (!this.items) return [];
      return map(this.items, item => ({
        ...item,
        type: map(item.type, type => type.name),
        workshop: map(item.workshop, workshop => workshop.name),
        products: map(item.products, product => product.name),
      }));
    },
    isReviewer() {
      return this.hasRole(ROLES.REVIEWER, 'spa');
    },
  },
  methods: {
    onDetail(id) {
      this.$router.push({ name: 'spa/ApplicationDetailView', params: { application: id }});
    },
    onPagination(params) {
      const { query } = this.$route;
      this.$router.push({ query: { ...query, ...params } });
    },
  }
}
</script>

<style module lang="scss">
.root {
  td {
    width: 500px;
    min-width: 130px;
    &:nth-child(1) { max-width: 50px; min-width: 50px; }
    &:nth-child(2) { max-width: 100px; }
    &:nth-child(3) { max-width: 105px; }
    &:nth-child(4) { max-width: 130px; }
    &:nth-child(5) { max-width: 100px; }
    &:nth-child(6) { max-width: 200px; }
    &:nth-child(7) { max-width: 180px; }
    &:nth-child(8) { max-width: 180px; }
    &:nth-child(9) { max-width: 180px; }
    &:nth-child(10) { max-width: 180px; }
    &:nth-child(11) { max-width: 200px; }
    &:nth-child(12) { max-width: 200px; }
    &:nth-child(13) { max-width: 180px; }
    &:nth-child(14) { max-width: 300px; }
  }
}
</style>
